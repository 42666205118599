import axios from 'axios';
import AppToast from '../components/AppToast.vue';
import {createApp} from "vue";

const api = axios.create({
  // baseURL: 'http://localhost:8000/perceptron/api',
  baseURL: 'https://api.yastai.com/perceptron/api',
});

let app = createApp(AppToast);
let toast = app.mount(document.createElement('div'));
document.body.appendChild(toast.$el);

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("accessToken");
      toast.displayToast(error.response.data.message);
      location.href = "/";
    }
    return Promise.reject(error);
  }
);

export default api;