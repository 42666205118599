<template>
  <div>
    <p>{{ toast }}</p>
  </div>
</template>

<script>
// import wx from 'weixin-js-sdk';
import { ref, onMounted } from 'vue';
// import api from "@/service/api";

export default {
  name: 'WechatPay',

  props: {
    orderData: {
      type: Object,
      required: true
    }
  },

  setup(props) {
    const toast = ref(null);

    const displayToast = (message) => {
      toast.value = message;
      setTimeout(() => { toast.value = null }, 3000);
    };

    const doPay = (data) => {
      if (typeof WeixinJSBridge == "undefined"){
        if( document.addEventListener ){
          document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
        }else if (document.attachEvent){
          document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
          document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
        }
      }else{
        onBridgeReady();
      }
    // const doPay = (data) => {
    //   wx.chooseWXPay({
    //     appId: data.appId,
    //     timestamp: data.timeStamp,
    //     nonceStr: data.nonceStr,
    //     package: data.package,
    //     signType: "RSA",
    //     paySign: data.paySign,
    //     success: function (res) {
    //       if(res.errMsg === "chooseWXPay:ok" ){
    //         displayToast("支付成功");
    //       } else if(res.errMsg === "chooseWXPay:cancel" ){
    //         displayToast("已取消支付");
    //       } else {
    //         displayToast("支付失败，请重试");
    //       }
    //     },
    //     fail: function (res) {
    //       console.log('支付失败:', res);
    //       displayToast("支付失败，请重试");
    //     }
    //   });
    // };

    // const initWeChat = async () => {
    //   try {
    //     const res = await api.get('/jsapi-signature');
    //     const config = res.data;
    //     wx.config({
    //       debug: true,
    //       appId: config.appId,
    //       timestamp: config.timestamp,
    //       nonceStr: config.nonceStr,
    //       signature: config.signature,
    //       jsApiList: ['chooseWXPay']
    //     });
    //     wx.ready(() => {
    //       doPay(props.orderData.data);
    //     });
    //   } catch (error) {
    //     console.log('微信支付初始化失败:', error);
    //     displayToast("微信支付初始化失败，请重试");
    //   }
    // };

    function onBridgeReady(){
      // eslint-disable-next-line no-undef
      WeixinJSBridge.invoke(
          'getBrandWCPayRequest', {
            "appId": data.appId,     //公众号名称，由商户传入
            "timeStamp": data.timeStamp, //时间戳，自1970年以来的秒数
            "nonceStr": data.nonceStr, //随机串
            "package": data.package,
            "signType": "RSA", //微信签名方式
            "paySign": data.paySign //微信签名
          },
          function(res){
            if(res.err_msg == "get_brand_wcpay_request:ok" ){
              displayToast("支付成功");
            } else if(res.err_msg === "get_brand_wcpay_request:cancel" ){
              displayToast("已取消支付");
            } else if (res.err_msg == "get_brand_wcpay_request:fail"){
              displayToast("支付失败，请重试");
            } else {
              displayToast("网络异常，请重试");
            }
          }
      );
    }
  };

    // onMounted(() => {
    //   initWeChat();
    // });
    onMounted(() => {
      doPay(props.orderData.data);
    });

    return {
      toast
    };
  }
};
</script>
