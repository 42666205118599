<template>
  <transition name="fade">
    <div v-if="show" class="app-toast">
      {{ message }}
    </div>
  </transition>
</template>

<script>
export default {
  name: 'AppToast',
  data() {
    return {
      show: false,
      message: '',
      timeoutId: null
    };
  },
  methods: {
    displayToast(msg, duration = 1000) {
      this.show = true;
      this.message = msg;

      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }

      this.timeoutId = setTimeout(() => {
        this.show = false;
      }, duration);
    }
  }
};
</script>

<style scoped>
.app-toast {
  position: fixed;
  bottom: 380px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  font-size: .9375rem;
  font-weight: 500;
  padding: 10px 20px;
  border-radius: 10px;
  text-align: center;
  z-index: 1000;
  /* 使用 flex 布局居中内容 */
  display: flex;
  justify-content: center;
  align-items: center;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
