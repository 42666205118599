<template>
  <div class="home">
    <div class="wallet">
      <div class="balance">会员尊享权益</div>
    </div>
    <div class="box">
      <div class="goods-list">
        <div class="goods">
          <div class="title">创作额度</div>
          <div class="desc">免费送3000字/周</div>
        </div>
        <div class="goods">
          <div class="title">超值字数包</div>
          <div class="desc">限时1元起购</div>
        </div>
        <div class="goods">
          <div class="title">专属客服</div>
          <div class="desc">7*24小时在线服务</div>
        </div>
        <div class="goods">
          <div class="title">智能对话</div>
          <div class="desc">专属个人助理</div>
        </div>
        <div class="goods">
          <div class="title">AI创作</div>
          <div class="desc">20+AI创作功能</div>
        </div>
        <div class="goods">
          <div class="title">更多权益</div>
          <div class="desc">持续更新中</div>
        </div>
        <div style="clear: both;"></div>
      </div>
    </div>
    <div class="main-area">
      <toast ref="toast"></toast>
      <div class="image">
        <img src="../assets/logo.png" alt="Logo">
      </div>
      <button @click="wxlogin" class="btn-login">微信授权登录</button>
      <div class="form-item">
        <label class="agreement">
          <input type="checkbox" v-model="is_agree" class="check" style="color:#04BABE">我以阅读并同意以下协议
        </label>
        <p @click="toUa" class="agreement" style="color:#1d9bf0;">《用户政策》</p>
        <p @click="toPrivacy" class="agreement" style="color:#1d9bf0;">《隐私协议》</p>
      </div>
    </div>
  </div>
</template>

<script>
import {onMounted, ref} from 'vue';
import {useRoute} from 'vue-router';
import Toast from '../components/AppToast.vue';
import router from "@/router";
import api from "@/service/api";

export default {
  name: "HomePage",
  components: {
    Toast
  },
  setup() {
    const route = useRoute();
    const wxParams = ref(null);
    const is_agree = ref(false);
    const toast = ref(null);
    wxParams.value = route.query;

    const wxlogin = () => {
      if (!is_agree.value) {
        toast.value.displayToast('请先阅读并同意协议');
        return;
      }
      window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxc1d254f59e049141&redirect_uri=https%3A%2F%2Fpay.yastai.com%2F&response_type=code&scope=snsapi_userinfo&state=STATE&connect_redirect=1#wechat_redirect";
    };

    const checkLogin = async () => {
      const token = localStorage.getItem('accessToken');
      if (token) {
        try {
          const response = await api.get('/user/checkLogin', {token: token});
          if (response.data.code === 0) {
            await router.push('/recharge');
          }
        } catch (error) {
          console.error(error);
          toast.value.displayToast('登录状态检查失败，请重新登录。');
        }
      }
    }

    // 页面加载完成后检查并处理code
    onMounted(async () => {
      await checkLogin();
      if (wxParams.value && wxParams.value.code) {
        try {
          const response = await api.post("/auth/login", {
            code: wxParams.value.code,
            type: "WECHAT",
          });
          if (response.data.code === 0) {
            localStorage.setItem('accessToken', response.data.data.token);
            await router.push('/recharge');
          } else {
            toast.value.displayToast(response.data.message);
          }
        } catch (error) {
          console.error(error);
          toast.value.displayToast('微信授权异常，请重试。');
        }
      }
    });

    const toUa = () => {
      window.open("https://www.yastai.com/ua.html", "_blank");
    };

    const toPrivacy = () => {
      window.open("https://www.yastai.com/privacy.html", "_blank");
    };

    return {
      toast,
      wxlogin,
      toUa,
      toPrivacy,
      wxParams,
      is_agree,
    }
  },
};
</script>

<style scoped>
.home {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

h1 {
  margin-top: 50px;
  color: red;
}

.main-area {
  height: 20.1875rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  padding: 20px;
  position: relative;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background: white;
  margin-top: auto;
}

.home::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.image {
  text-align: center;
}

.image img {
  height: 90px;
  width: 232px;
}

.btn-login {
  align-items: center;
  background-color: #1d9bf0;
  border: none;
  color: #fff;
  height: 2.5rem;
  display: flex;
  font-size: .9375rem;
  font-weight: 500;
  justify-content: center;
  letter-spacing: 2px;
  margin: 40px 0 0;
  padding: 10px;
  width: 100%;
  line-height: 1.25rem;
  border-radius: 0.25rem;
  margin-top: 2.5rem;
}

.form-item {
  width: 100%;
  display: flex;
  font-size: .75rem;
  color: #333;
  font-weight: 400;
  line-height: 1.25rem;
}

.agreement {
  display: flex;
  align-items: center;
}

.wallet {
  align-items: center;
  background: #1d9bf0;
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 100px;
  margin-bottom: -100px;
  padding-bottom: 95px;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.wallet .balance {
  color: #fff;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.25rem;
  letter-spacing: 2px;
  text-align: center;
}

.goods-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.goods {
  background: linear-gradient(180deg,#fefaee 100%,#fefcf4 100%);
  border-radius: 20px;
  box-sizing: border-box;
  /*margin-right: 20px;*/
  margin: auto;
  margin-top: 30px;
  padding: 20px 0;
  text-align: center;
  width: 100px;
  height: 100px;
}

.goods .title {
  align-items: baseline;
  display: flex;
  margin-top: 0.75rem;
  color: #333;
  font-weight: 500;
  font-size: 1rem;
  line-height: .9375rem;
  justify-content: center;
  text-align: center;
}

.goods .desc {
  margin-top: 0.25rem;
  font-size: .7rem;
  color: #999;
  font-weight: 500;
  line-height: .9375rem;
}

.goods .price text {
  color: red;
  margin: 0 6px;
}

.box {
  background: #fff;
  border-radius: 10px;
  box-sizing: border-box;
  overflow: hidden;
  width: 94%;
}

.box .box-title {
  color: #333;
  font-size: 32px;
  font-weight: 700;
  height: 72px;
  line-height: 72px;
  width: 100%;
}

.box .box-title .desc {
  color: #999;
  font-size: 28px;
  font-weight: 400;
  margin-left: 20px;
}
</style>
