<template>
  <div class="home">
    <div class="wallet">
      <div class="balance">限时优惠字数包</div>
    </div>
    <div class="box">
      <div class="box-title">
        <span>选择字数包</span>
        <span class="desc">专属客户支持</span>
      </div>
      <div v-if="vipList && vipList.length > 0">
        <div class="goods-list">
            <div
                v-for="(item, index) in vipList"
                :key="index"
                class="goods"
                :class="{ active: item.id === vip_id }"
                @click="chooseVip(item.id,item.price)"
            >
            <div class="title">
              <span class="num">{{ item.num }}</span>
            </div>
            <div class="price">特价<span>{{ item.price }}</span>元</div>
            <div v-if="item.marketPrice > 0" class="market_price">原价{{ item.marketPrice }}元</div>
          </div>
          <div style="clear: both;"></div>
        </div>
      </div>
    </div>
    <div class="main-area">
      <toast ref="toast"></toast>
      <button @click="doPayVip" class="btn-pay">立即购买</button>
      <wechat-pay type="primary" v-if="orderData" :orderData="orderData"></wechat-pay>
      <div class="form-item">
        <label class="agreement">
          <input type="checkbox" v-model="is_agree" class="check" style="color:#04BABE">我以阅读并同意
        </label>
        <p @click="toUa" class="agreement" style="color:#1d9bf0;">《千手AI充值服务协议》</p>
      </div>
    </div>
  </div>
</template>

<script>
import {onMounted, ref} from 'vue';
import Toast from '../components/AppToast.vue';
import WechatPay from '../components/WechatPay.vue';
import api from "@/service/api";

export default {
  name: "PayPage",
  components: {
    Toast,
    WechatPay,
  },

  setup() {
    const is_agree = ref(false);
    const toast = ref(null);
    const vipList = ref([]);
    const vip_id = ref(null);
    const price = ref(null);
    const orderData = ref(null);

    const chooseVip = (id,priceParam) => {
      vip_id.value = id;
      price.value = priceParam;
    };

    const doPayVip = async () => {
      try {
        if (!is_agree.value) {
          toast.value.displayToast('请先阅读并同意协议');
          return;
        }
        let productId = vip_id.value;
        if (!productId) {
          toast.value.displayToast("请选择VIP套餐");
          return;
        }
        const response = await api.post("/order", {
          productId: productId,
          payType: 2
        });

        if (response.data.code !== 0) {
          toast.value.displayToast(response.data.message);
          return;
        }
        console.log('order response:', response.data);

        orderData.value = response.data;
      } catch (error) {
        console.error(error);
        toast.value.displayToast("网络异常，请重试");
      }
    };

    onMounted(async () => {
      try {
        const response = await api.get("/price/getPriceList");
        if (response.data.code === 0) {
          vipList.value = response.data.data;
          // 获取默认选中的产品ID
          const defaultProduct = response.data.data.find(item => item.isDefault === 1);
          if (defaultProduct) {
            vip_id.value = defaultProduct.id;
            price.value = defaultProduct.price;
          }
        } else {
          toast.value.displayToast(response.data.message);
        }
      } catch (error) {
        console.error(error);
      }
    });

    const toUa = () => {
      window.open("https://www.yastai.com/ua.html", "_blank");
    };

    return {
      toast,
      toUa,
      chooseVip,
      vipList,
      vip_id,
      price,
      is_agree,
      orderData,
      doPayVip
    }
  },
};
</script>

<style scoped>
.home {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.main-area {
  height: 40vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 20px;
  position: relative;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background: white;
  margin-top: auto;
}

.image img {
  height: 90px;
  width: 232px;
}

.btn-pay {
  align-items: center;
  background: linear-gradient(-18deg,#f4d082,#f4e4ab);
  color: #a25a14;
  border: none;
  display: flex;
  font-size: 4.733vw;
  font-weight: 500;
  height: 13.733vw;
  justify-content: center;
  letter-spacing: 2px;
  margin: 40px 0 0;
  padding: 10px;
  width: 100%;
  line-height: 1.25rem;
  border-radius: 5.867vw;
  margin-top: 2.5rem;
}

.form-item {
  width: 100%;
  display: flex;
  font-size: .75rem;
  color: #333;
  font-weight: 400;
  line-height: 1.25rem;
}

.agreement {
  display: flex;
  align-items: center;
}

.wallet {
  align-items: center;
  background: #1d9bf0;
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 100px;
  margin-bottom: -100px;
  padding-bottom: 95px;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.wallet .balance {
  color: #fff;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.25rem;
  letter-spacing: 2px;
  text-align: center;
}

.goods-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.goods {
  background: #f3f6f9;
  border: 2px solid #f3f6f9;
  border-radius: 10px;
  box-sizing: border-box;
  margin: auto;
  margin-top: 30px;
  padding: 5px 0;
  text-align: center;
  width: 100px;
  /*height: 100px;*/
}

.goods .title {
  align-items: baseline;
  display: flex;
  font-size: 1.5rem;
  font-weight: 700;
  justify-content: center;
}

.goods .price {
  color: #444;
  margin: 3px 0;
}

.goods .price span {
  color: red;
  margin: 0 6px;
}

.goods .market_price {
  color: #999;
  font-size: 0.85rem;
  text-decoration: line-through;
}

.goods.active {
  border-color: #1d9bf0;
}

.box {
  background: #fff;
  border-radius: 10px;
  box-sizing: border-box;
  overflow: hidden;
  width: 94%;
}

.box .box-title {
  color: #333;
  font-size: 1rem;
  font-weight: 700;
  height: 72px;
  line-height: 72px;
  width: 100%;
  max-height: 40px;
}

.box .box-title .desc {
  color: #999;
  font-size: 0.8rem;
  font-weight: 400;
  margin-left: 20px;
}
</style>
