import { createRouter, createWebHistory } from 'vue-router'

import HomePage from '../views/Home.vue'
import PayPage from '../views/Pay.vue'


const routes = [
  {
    path: "/",
    name: "HomePage",
    component: HomePage,
  },
  {
    path: "/recharge",
    name: "PayPage",
    component: PayPage,
    meta: { requiresAuth: true },
  },
  ];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const token = localStorage.getItem('accessToken');

  if (requiresAuth && !token) {
    next('/');  // 重定向到首页
  } else {
    next(); // 确保一定要调用 next()
  }
});
export default router